<template>
    <div class="coverPage A4Container flexCenter pageBreak_no">
        <div class="flexCloumnBetween" style="width: 80%;height: 80%;">

            <div>
                <div class="subTitle"> <img :src='logo' alt='' style='width: 100px;'></div>
                <div class='title fontFamily_HWZS' style='font-weight: 500;font-size: 36px;'>
                    毕&nbsp;业&nbsp;实&nbsp;习&nbsp;手&nbsp;册</div>
            </div>
            <div class="form">
                <div class="formItem flexStart">
                    <div class="label fontFamily_HWZS">学校</div>
                    <div class="input fontFamily_HWZS">{{ schoolName || '-' }}</div>
                </div>

                <div class="formItem flexStart">
                    <div class="label fontFamily_HWZS">专业</div>
                    <div class="input fontFamily_HWZS">{{ stuInfo.yx_name || '-' }}</div>
                </div>
                <div class="formItem flexStart">
                    <div class="label fontFamily_HWZS">班级</div>
                    <div class="input fontFamily_HWZS">{{ stuInfo.class_name || '-' }}</div>
                </div>
                <div class="formItem flexStart">
                    <div class="label fontFamily_HWZS">学生姓名</div>
                    <div class="input fontFamily_HWZS">{{ stuInfo.stu_name || '-' }}</div>
                </div>
                <div class="formItem flexStart">
                    <div class="label fontFamily_HWZS">实习单位</div>
                    <div class="input fontFamily_HWZS">{{ stuInfo.firm_name || '-' }}</div>
                </div>
            </div>
        </div>



    </div>
</template>

<script>
export default {
    name: 'cover',
    props: {
        stuInfo: {
            type: Object,
            default: {}
        },
        logo: {
            type: String,
            default: ''
        },
        schoolName: {
            type: String,
            default: ''
        },
    }
}
</script>
